import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import FormWithType from "../form_with_type";

const CustomButton = ({filled= false, text='#',linkTo = '#', btnwidth='w-3/4', styles='', click= false,typeScam = '',headerBtn = false}) => {
    const [typeClick, setTypeClick] = React.useState(false)
    function handleClick() {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        if (!click) return false
    }
    const handleClickLink = (clickL) => {
        if(typeClick){
            document.body.classList.remove('hideScrollY')
        } else if ( typeScam !== '') {
            document.body.classList.add('hideScrollY')
        }
        if (!typeScam) return false
        if(clickL === undefined) {setTypeClick(true)}
        else {
            setTypeClick(clickL)
        }


        return typeClick
    }


    return (
        <>
            {filled == true ? <Link to={linkTo} onClick={
                (event) => {
                    handleClickLink();
                    handleClick()
                }
            } className={`py-3 px-9 ${ styles ? styles : 'bg-accentFontColor hover:bg-accentHoverColor border-transparent secondFontColor border-2'} ${btnwidth} text-center font-semibold  transition `}>{text}</Link> : <Link to={linkTo} onClick={handleClick} className={`${ styles ? styles : 'bg-transparent border-white text-white hover:bg-secondBGColor hover:text-mainBGColor border-2'} text-center py-3 px-9  font-semibold   transition   ${btnwidth} ${headerBtn ? 'headerButton' : ''} `}>{text}</Link>}
            { typeScam ? <FormWithType scamType={typeScam} click={ typeClick && handleClickLink}/> : null}
        </>
    );
};

export default CustomButton;